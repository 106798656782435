.nav {
    display: flex;
    justify-content: space-between;
    background-color: #1b1f23f5;
    opacity: 1.0;
    width: 100%;
    transition: opacity 0.5s;
}

.nav-main-container {
    display: flex;
    justify-content: space-between;
}

.nav-logo {
    display: flex;
    align-items: center;
    padding-left: 6pc;
}

.nav-logo-img {
    margin: 2px;
}

.nav-logo-text {
    padding-left: 10px;
    font-weight: bold;
}

.nav-hamburger {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding-right: 1pc;
}

.nav-links-container {
    display: flex;
    align-items: stretch;
    padding-right: 6pc;
}

.nav-links-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
}

.nav-link-li, .nav-link-li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 61px;
}

.nav-link-li:hover {
    background-color: #2c333a;
}

.nav-logo-text, .nav-link {
    color: #ffffff;
}

.nav-logo, .nav-link {
    text-decoration: none;
}

.nav-link {
    padding: 0 16px;
}

@media screen and (max-width: 800px) {
    .nav {
        flex-direction: column;
    }

    .nav-logo {
        padding-left: 0;
    }

    .nav-hamburger {
        display: flex;
    }

    .nav-links-ul {
        display: none;
    }

    .nav-links-container {
        justify-content: center;
        padding-right: 0;
    }

    .nav-links-ul.opened {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .nav-link-li, .nav-link-li a {
        height: 40px;
    }

}

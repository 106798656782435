h2 {
    font-size: 1.8rem;
    margin: 1em auto 0.4em;
    text-align: center;
    color: #ffffff;
    letter-spacing: .5rem;
    text-shadow: #ffffff 0 0 8px;
    text-transform: uppercase;
}

.text-link:link, .text-link:visited, .text-link:hover, .text-link:active {
    color: #ffffff;
    text-decoration-color: #ffffff;
    text-decoration-thickness: 0.1rem;
    text-decoration-style: solid;
}

.text-link:hover {
    text-decoration-color: #ffffff;
}

.sub-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: underline;
    padding-top: 1.4em; /* for first child: padding must be 0 */
}

.sub-category {
    font-size: 0.9rem;
    font-weight: bold;
    text-decoration: underline;
    padding-top: 1.0em; /* for first child: padding must be 0 */
}

thead {
    font-weight: bold;
}

table {
    border-collapse: collapse;
}

td, th {
    border: 1px solid #000000;
    background-color: #2b3238;
    padding-right: 7px;
    padding-left: 7px;
}

th {
    background-color: #31383f;
    color: #ffffff;
    height: 30px;
}
.priv-container {
    padding-left: 100px;
}

.priv-description {
    max-width: 800px;
}

@media screen and (max-width: 800px) {
    .priv-container {
        padding-left: 5px;
    }
}

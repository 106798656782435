.network-container {
    display: inline-block;
    text-align: center;
}

.network-title {
    padding-bottom: 10px;
}

.network-logo {
    margin: 7px;
    filter: drop-shadow(5px 5px 2px #202020);

    /* animation start */
    transform: scale(0.6, 0.6);
    opacity: 0.2;
}

.network-logo-wrapper {
    display: inline-block;
}

.network-logo-wrapper:hover {
    filter: drop-shadow(6px 6px 2px #202020);
    transform: scale(1.1, 1.1);
}

@keyframes network-logo-anim {
    to {
        transform: scale(1, 1);
        opacity: 1.0;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .network-logo-anim-trigger {
        animation: network-logo-anim 1.5s 1;
        animation-fill-mode: forwards; /*leaves the animation in the state of the last frame */
    }
}
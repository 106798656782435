.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.blog-preview, .blog-content {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    padding: 5px 10px;
    font-size: 1.0rem;
    background-color: #2b3238;
    border-radius: 10px;
    margin-bottom: 2.5rem;
}

.blog-content {
    display: flex;
    flex-direction: column;
}

.blog-content p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.center {
    text-align: center;
}

.blog-title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.3em;
}

.blog-sub-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 1.5em;
    margin-bottom: 0.75em;
    text-decoration: underline;
    text-decoration-style: dotted;
}

.blog-preview-main {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    margin-top: 0.75em;
}

.blog-img {
    border-radius: 10px;
    border: 1px solid white;
    max-width: 100%;
    height: auto;
}

.blog-img-trans {
    align-self: center;
    max-width: 100%;
    height: auto;
}

.blog-metadata {
    font-size: 0.9rem;
    font-style: italic;
    margin-top: 1em;
}

.graph-container {
    width: 75%;
    height: 300px;
}

@media screen and (max-width: 500px) {
    .blog-preview-main {
        flex-direction: column;
    }

    .graph-container {
        width: 95%;
    }
}
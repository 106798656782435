.game-container {
    padding-left: 100px;
}

.pe-logo {
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
    left: -2%;
    width: 35%;
    height: 35%;
}

.game-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 25px;
}

.game-description {
    max-width: 800px;
    padding: 5px 12px;
    margin-right: 53px;
    font-size: 1.0rem;
    font-family: "Verdana", sans-serif;
    background-color: #2b3238;
    border-radius: 10px;
}

.p-game-description {
    line-height: 1.4em;
    margin-bottom: 1em;
}

.p-game-description:last-child {
    margin-bottom: 0;
}

.game-list > li {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.game-soc-container {
    display: inline-block;
    padding: 5px 12px;
    margin-bottom: 25px;
    background-color: #2b3238;
    border-radius: 10px;
}

.content-modal {
    display: none;
    justify-content: center;
    position: fixed; /* stay in place */
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.8);
}

.trailer-modal-content {
    margin: auto;
    width: 60%;
    height: auto;
    aspect-ratio: 16 / 9;
    border: 2px solid #ffffff;
    border-radius: 10px;
    display: block;
}

.game-screenshots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80%;
    padding-bottom: 25px;
}

.game-screenshot {
    width: 31%;
    aspect-ratio: 16 / 9;
    padding: 10px;
}

.game-screenshot-img {
    width: 100%;
    height: 100%;
    border: 2px solid #ffffff;
    border-radius: 10px;
    cursor: zoom-in;
}

.game-screenshot-img:hover {
    opacity: 0.5;
}

.screenshots-modal-content {
    margin: auto;
    width: 80%;
    max-width: 1100px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    animation-name: img-zoom;
    animation-duration: 0.6s;
}

@keyframes img-zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

@media screen and (max-width: 1500px) {
    .pe-logo {
        width: 50%;
        height: 50%;
    }
}

@media screen and (max-width: 1300px) {
    .game-container {
        padding-left: 5px;
    }

    .pe-logo {
        width: 60%;
        height: 60%;
    }

    .game-screenshot {
        width: 46%;
        height: 46%;
    }
}

@media screen and (max-width: 800px) {
    .game-container {
        padding-left: 5px;
    }

    .pe-logo {
        width: 95%;
        height: 95%;
    }

    .game-info-container {
        flex-direction: column;
        align-items: center;
    }

    .game-description {
        padding-right: 0;
        padding-left: 5px;
        width: 100%;
    }

    .trailer-modal-content {
        width: 95%;
    }

    .game-screenshots {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .game-screenshot {
        width: 100%;
        height: 100%;
    }

    .game-screenshot-img {
        cursor: auto;
    }

    .game-screenshot-img:hover {
        opacity: 1.0;
    }

}

.pres-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 50px;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.pres-profile {
    float : left;
    margin-right: 15px;
    margin-bottom: 2px;
}

.pres-description {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.pres-description div {
    max-width: 600px;
}

.pres-logo {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pres-logo img {
    width: 300px;
    height: auto;
}

@media screen and (max-width: 800px) {
    .pres-container {
        flex-direction: column;
    }

    .pres-description {
        align-items: center;
    }

    .pres-logo img {
        width: 200px;
    }
}

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    background: #23282d;
    font-family: Verdana, sans-serif;
    font-size: 18px;
    color: #ffffff;
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
}

ul {
    padding-left: 1.4em;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    width: 90%;
}

footer {
    background-color: #1b1f23;
    width: 100%;
}

.soc-container {
    text-align: center;
}

.company-container {
    text-align: center;
    font-size: 0.95rem;
    margin-top: 1.0em;
    margin-bottom: 1.5em;
}

.company-container > p {
    margin-bottom: 0.5em;
}

@media screen and (max-width: 800px) {
    .content {
        width: 96%;
    }
}
.btn {
    display: inline-block;
    width: 260px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    font-size: 1.15rem;
    filter: drop-shadow(0px 1px 2px #202020);
    border: 3px solid #ffffff;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 10px;
}

.btn::after {
    position: absolute;
    content: "";
    width: 0;
    left: 50%;
    height: 1px;
    background-color: #ffffff;
    bottom: 8px;
    transition: all 0.4s ease-in-out;
}

.btn:hover {
    background-color: #1b1f23;
    cursor: pointer;
}

.btn:hover::after {
    width: 88%;
    left: 6%;
}

.btn-text {
    /* animation start */
    transform: translate(0px, -10px);
}

.btn-text img {
    vertical-align: -3px;
}

@keyframes btn-text-anim {
    to {
        transform: translate(0px, 0px);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .btn-text-anim-trigger {
        animation: btn-text-anim 1.5s 1;
        animation-fill-mode: forwards; /*leaves the animation in the state of the last frame */
    }
}
